import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { dataPage } from '../data/data';
import LanguageColumns from './LanguageColumns';
import meStudy from '../img/me-study.png';

export const Languages = () => {

    const { languages } = dataPage;
    
    return (
        <section id="languages">
            <div className="container-fluid py-5 text-center mt-2 bg-secondary">
                <h1 className="display-5 fw-bold mb-4 text-white section-title" data-aos="fade-up">
                    <FontAwesomeIcon icon={faStar} className='fa-xs' />
                    Languages
                    <FontAwesomeIcon icon={faStar} className='fa-xs' />
                </h1>
                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <table className="table table-borderless text-white mt-5">
                            <thead>
                                <tr>
                                    <th scope="col">Image</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Level</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    languages.map(len => 
                                        <LanguageColumns key={ len.id_language } { ...len }/>
                                    )
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-6">
                        <img src={ meStudy } className="img-fluid img-responsive" alt=""></img>
                    </div>
                </div>
            </div>
        </section>
    )
}