import { ListTechGridItem } from "./ListTechGridItem";

export const TableCollapse = ({ data,id_table,inputRef }) => {
    return (
        <tr className={"collapse animate__animated animate__fadeIn"} id={"tbl"+id_table}>
            <td colSpan="4">
                <div className='container-fluid'>
                    <ul className="list-group-sm">
                        {
                            data.map(d => 
                                <ListTechGridItem key={d.id_tech} {...d} />
                            )
                        }
                    </ul>
                </div>
            </td>
        </tr>
    )
}