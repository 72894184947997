import { useState } from 'react'
import { faArrowCircleDown, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { useCollapseAnimation } from './useCollapseAnimation';

export const useCollapse = (id_table) => {
    const [icon, setIcon] = useState(faArrowCircleRight);

    const [collapse, setCollapse] = useState(true);

    const [colorIcon, setColorIcon] = useState('primary');

    const changeIcon = () => {
        (collapse) ? setIcon(faArrowCircleDown) : setIcon(faArrowCircleRight);
        (collapse) ? setColorIcon('danger') : setColorIcon('primary');
        setCollapse(!collapse);
    }

    useCollapseAnimation( id_table, collapse );

    return [ icon, colorIcon, changeIcon ];
}
