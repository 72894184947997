import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import meLogo from '../img/me-logo.png';

export const MainPresentation = () => {
    return (
        <main id="main">
            <div className="p-5 bg-secondary">
                <div className="container-fluid py-5 text-center">
                    <img src={ meLogo } className="img-fluid img-responsive" alt="" />
                    <h1 className="display-5 fw-bold text-white">
                        <FontAwesomeIcon icon={ faStar } className='fa-xs' />
                        Sebastián Huaitro
                        <FontAwesomeIcon icon={ faStar } className='fa-xs' />
                    </h1>
                    <h2 className="display-10 fw-bold text-white">Developer</h2>
                    <p className="col-md-12 fs-4">Hi! I am sebastián huaitro a video game lover and <br></br>I love learning new things, I live in Santiago de Chile and this is my website 🤖</p>
                </div>
            </div>
        </main>
    )
}