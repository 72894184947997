export const dataPage = {
    languages: [
        {
            'id_language': 1,
            'language': 'Java',
            'level': 'high-level',
            'image': 'http://cdn2.dineroenimagen.com/media/dinero/styles/xlarge/public/images/blogs/javalogo.jpg'
        },
        {
            'id_language': 2,
            'language': 'Javascript',
            'level': 'high-level',
            'image': 'https://upload.wikimedia.org/wikipedia/commons/9/99/Unofficial_JavaScript_logo_2.svg'
        },
        {
            'id_language': 3,
            'language': 'Typescript',
            'level': 'medium-level',
            'image': 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Typescript_logo_2020.svg'
        }
    ],
    tech: [
        {
            frontend: [
                {
                    'id_tech': 1,
                    'tech': 'SpringBoot',
                    'level': 'high-level',
                    'image': 'https://i.imgur.com/w4gy5AK.png'
                },
                {
                    'id_tech': 2,
                    'tech': 'Jquery',
                    'level': 'high-level',
                    'image': 'https://ausago.com/wp-content/uploads/2011/03/jquery-logo.png'
                },
                {
                    'id_tech': 3,
                    'tech': 'Thymeleaf',
                    'level': 'high-level',
                    'image': 'https://programandoointentandolo.com/wordpress/wp-content/uploads/2019/02/thymeleaf.png'
                },
                {
                    'id_tech': 4,
                    'tech': 'Bootstrap 4',
                    'level': 'medium-level',
                    'image': 'https://brandslogo.net/wp-content/uploads/2016/06/bootstrap-logo-vector-download.jpg'
                },
                {
                    'id_tech': 5,
                    'tech': 'React',
                    'level': 'entry-level',
                    'image': 'https://res.cloudinary.com/codier/image/upload/v1530614273/jqxbwxmnrkjq0mxhnvjn.png'
                }
            ],
            backend: [
                {
                    'id_tech': 1,
                    'tech': 'SpringBoot',
                    'level': 'high-level',
                    'image': 'https://i.imgur.com/w4gy5AK.png'
                },
                {
                    'id_tech': 2,
                    'tech': 'Node js',
                    'level': 'basic-level',
                    'image': 'https://img2.freepng.es/20180410/qgw/kisspng-node-js-javascript-database-mongodb-native-5acd4ebf6b4b75.3634484415234044794395.jpg'
                },
                {
                    'id_tech': 3,
                    'tech': 'NestJs',
                    'level': 'basic-level',
                    'image': 'https://res.cloudinary.com/practicaldev/image/fetch/s--yblwVfXu--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://thepracticaldev.s3.amazonaws.com/i/2xwnrswa6wfscoknc63c.png'
                }
            ],
            other: [
                {
                    'id_tech': 1,
                    'tech': 'git',
                    'level': 'medium-level',
                    'image': 'https://i.imgur.com/J1CDYfH.png'
                },
                {
                    'id_tech': 2,
                    'tech': 'Jira',
                    'level': 'medium-level',
                    'image': 'https://i.imgur.com/uYW6HGc.png'
                },
                {
                    'id_tech': 3,
                    'tech': 'Docker',
                    'level': 'entry-level',
                    'image': 'https://www.docker.com/wp-content/uploads/2022/03/Moby-logo.png'
                }
            ]
        }
    ],
    projects: [
        {
            'id_project': 1,
            'name': 'Frukty',
            'description':'This project took 3 months to complete and was built as a solution for a title portfolio.',
            'status': 'finalized',
            'time':'3 months',
            'image': 'https://shuaitro.info/assets/img/portfolio/frukty.png'
        },
        {
            'id_project': 2,
            'name': 'Seysa',
            'description':'This project took 1 month, it was carried out as a project in the labor practice :)',
            'status': 'finalized',
            'time':'2 months',
            'image': 'https://shuaitro.info/assets/img/portfolio/seysa.png'
        },
        {
            'id_project': 3,
            'name': 'EIS-Admin',
            'description':'Developing :)',
            'status': 'developing',
            'time':'2 months',
            'image': 'https://i.imgur.com/Rkx6eMB.png'
        },
        {
            'id_project': 4,
            'name': 'EIS-Ecommerce',
            'description':'Developing',
            'status': 'Developing',
            'time':'2 months',
            'image': 'https://i.imgur.com/Rkx6eMB.png'
        },
        {
            'id_project': 5,
            'name': 'EIS-RRHH',
            'description':'Developing :)',
            'status': 'developing',
            'time':'2 months',
            'image': 'https://i.imgur.com/Rkx6eMB.png'
        },
        {
            'id_project': 6,
            'name': 'EIS-FINANCE',
            'description':'Developing :)',
            'status': 'developing',
            'time':'2 months',
            'image': 'https://i.imgur.com/Rkx6eMB.png'
        },
    ]
}
