import '../css/footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faGithubAlt,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons';  
    library.add (
    faGithubAlt,
    faLinkedin,
    );

const Footer = () => {
    return (
        <div className="mt-4">
            <footer className="text-center text-lg-start text-white bg-dark">
                <section
                    className="d-flex justify-content-between p-4 bg-red"
                >
                    <div className="me-5">
                        <span>Get connected with us on social networks:</span>
                    </div>

                    <div>
                        <a href="https://www.linkedin.com/in/sebasti%C3%A1n-alejandro-huaitro-maldonado-23b778198/" className="text-white me-4">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a href="https://github.com/Shm98-exe" className="text-white me-4">
                            <FontAwesomeIcon icon={faGithubAlt}/>
                        </a>
                    </div>
                </section>
                <section className="">
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">About Page</h6>
                                <hr
                                    className="mb-4 mt-0 d-inline-block mx-auto"
                                />
                                <p>
                                    This is an information page about me, made by me with React
                                </p>
                            </div>
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">Location</h6>
                                <p>
                                    Santiago de chile, Puente alto
                                </p>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold">Contact</h6>
                                <hr
                                    className="mb-4 mt-0 d-inline-block mx-auto"
                                />
                                <p><i className="fas fa-envelope mr-3"></i> contact@shuaitro.info</p>
                                <p><i className="fas fa-phone mr-3"></i> seba.huaitro98@gmail.com</p>
                                <p><i className="fas fa-print mr-3"></i> + 56 9 5537 9453</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="text-center p-3">
                    © { new Date().getFullYear() } Copyright:
                    <a className="text-white" href="#/">SAHM</a>
                </div>
            </footer>
        </div>
    );
}

export default Footer;