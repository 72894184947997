import { changeColor } from '../utils/SahmUtils';

export const ListTechGridItem = ({ id_tech,image,tech,level }) => {
    return (
        <li key={id_tech} className="list-group-item d-flex justify-content-between align-items-center">
            <img src={image} alt="logo" height="20"/>
                {tech}
            <span className={"rounded-pill badge bg-"+changeColor(level)}>{level}</span>
        </li>
    )
}