import { useEffect } from "react";

export const useCollapseAnimation = (id_table,collapse) => {
    useEffect(() => {
        let trExpanded = document.querySelector(`#tbl${id_table}`);
        if(!collapse){
            trExpanded.classList.remove("animate__fadeOut");
            trExpanded.classList.add('animate__fadeIn');
        }else{
            trExpanded.classList.remove("animate__fadeIn");
            trExpanded.classList.add('animate__fadeOut');
        }
        return () => {
            trExpanded = null;
        }
    }, [collapse,id_table]);
}
