import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../css/Tech.css';
import { useCollapse } from '../hooks/useCollapse';

export const IconTableExpansive = ({id_table}) => {

    const [icon,colorIcon,changeIcon] = useCollapse(id_table);

    return (
        <td>
            <a className={"btn btn-" + colorIcon + " btn-sm btn-circle"} onClick={changeIcon} data-bs-toggle="collapse" href={"#tbl" + id_table} role="button" aria-expanded="false" aria-controls={id_table}>
                <FontAwesomeIcon icon={icon} />
            </a>
        </td>
    )
}