
import './App.css';
import { Languages } from './components/Language';
import { MainPresentation } from './components/MainPresentation';
import { Navbar } from './components/Navbar';
import { Portfolio } from './components/Portfolio';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Footer from './components/Footer';
import { Tech } from './components/Tech';
import 'animate.css';

function App() {
  return (
    <div className='animate__animated animate__fadeIn'>
      <Navbar />
      <MainPresentation />
      <Portfolio />
      <Languages />
      <Tech />
      <Footer />
    </div>
  );
}

export default App;
