import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { dataPage } from '../data/data';
import CardGridItem from './CardGridItem';

export const Portfolio = () => {

    const { projects } = dataPage;
    return (
        <section id="portfolio" className='animate__animated animate__fadeIn'>
            <div className="container-fluid py-5 text-center mt-2">
                <h1 className="display-5 fw-bold mb-4">
                    <FontAwesomeIcon icon={faStar} className='fa-xs' />
                    Portfolio
                    <FontAwesomeIcon icon={faStar} className='fa-xs' />
                </h1>
                <div className="row container-fluid">
                        {
                            projects.map(pro => 
                                <div key = {pro.id_project} className='col-sm-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center'>
                                    <CardGridItem {...pro} className='text-center'/>
                                </div>
                            )
                        }
                </div>
            </div>
        </section>
    )
}