import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { dataPage } from '../data/data';
import meComputer from '../img/me-computer.png';
import { IconTableExpansive } from './IconTableExpansive';
import { TableCollapse } from './TableCollapse';

export const Tech = () => {

    const [tech] = dataPage.tech;
    const frontEnd = tech['frontend'];
    const backEnd = tech['backend'];
    const other = tech['other'];

    return (
        <section id="tech" className='animate__animated animate__fadeIn'>
            <div className="container-fluid py-5 text-center mt-2">
                <h1 className="display-5 fw-bold mb-4 section-title" data-aos="fade-up">
                    <FontAwesomeIcon icon={faStar} className='fa-xs' />
                    Techs
                    <FontAwesomeIcon icon={faStar} className='fa-xs' />
                </h1>
                <div className="row">
                    <div className="col-lg-6">
                        <img src={meComputer} className="img-fluid img-responsive" alt=""></img>
                    </div>
                    <div className="col-lg-6 mt-3">
                        <table className="table table-borderless text-dark mt-5 animate__animated animate__fadeIn">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <IconTableExpansive {...{id_table: 1}} />
                                    <td>FrontEnd</td>
                                </tr>
                                <TableCollapse data={frontEnd} id_table={1}/>
                                <tr>
                                    <IconTableExpansive {...{id_table: 2}} />
                                    <td>BackEnd</td>
                                </tr>
                                <TableCollapse data={backEnd} id_table={2}/>
                                <tr>
                                    <IconTableExpansive {...{id_table: 3}} />
                                    <td>Other</td>
                                </tr>
                                <TableCollapse data={other} id_table={3} />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    )
}